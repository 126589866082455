<template>
  <sign-page
    title-text="诊所管理"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :table-actions="tableActions"
    :form-parms-add="formParmsAdd"
    :data-file-parm="dataFileParm"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  clinicRequest as request
} from '@/api'

export default {
  computed: {
    dataFileParm () {
      return {
        pDirPath: '/clinic/'
      }
    },
    tableActions () {
      let data = [
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '名称',
          key: 'name'
        },
        {
          type: 'input',
          label: '别称',
          key: 'otherName'
        },
        {
          type: 'input',
          label: '编码',
          key: 'code'
        },
        // {
        //   type: 'select',
        //   label: '上级诊所',
        //   key: 'p_id',
        //   selectDatas: list.map(v => {
        //     return { label: v.name, key: v.id }
        //   })
        // },
        {
          type: 'input',
          label: '电话',
          key: 'phone'
        },
        {
          type: 'input',
          label: '地址',
          key: 'address'
        },
        {
          type: 'input',
          label: '说明',
          key: 'description'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '名称',
          field: 'name',
          fixed: 'left',
          sort: true
        },
        {
          title: '别称',
          field: 'otherName',
          sort: true
        },
        {
          title: '编码',
          field: 'code',
          sort: true
        },
        // {
        //   title: '上级诊所',
        //   field: 'p_id',
        //   render (h, row) {
        //     const p = list.find(v => v.id)
        //     return row.p_id && p ? h('div', p.name) : ''
        //   }
        // },
        {
          title: '电话',
          field: 'phone',
          sort: true
        },
        {
          title: '地址',
          field: 'address',
          sort: true
        },
        {
          title: '说明',
          field: 'description',
          sort: true
        }]
        return data
      }
    }
  },
  data () {
    return {
      data: [],
      request: {
        get: async () => {
          this.data = await request.get()
          return this.data
        },
        add: request.add,
        update: request.update,
        del: request.del
      }
    }
  }
}
</script>